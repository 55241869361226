.PDFViewer canvas {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;
}

.PageNumberDisplay {
	margin-top: 10px;
	font-size: 16px;
	color: #333;
}

.PDFViewer {
  background-color: #f2f2f2;
  overflow-y: auto; 
  max-height: 700px;
}

.PageNumberDisplay {
  color: white; 
}
