@tailwind base;
@tailwind components;
@tailwind utilities;
@use postcss-preset-env;
@use postcss-nested-ancestors;
@use postcss-nested;

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 3s infinite;
  content: '';
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

button[editext='cancel-button'] {
  font-size: 16px !important;
  width: 16px !important;
  border: 0 !important;
  padding: 5px !important;
}

button[editext='save-button'] {
  font-size: 16px !important;
  width: 16px !important;
  border: 0 !important;
  padding: 5px !important;
}

button[editext='edit-button'] {
  font-size: 12px !important;
  width: 14px !important;
  border: 0 !important;
  padding: 0 !important;
}

input[editext='input'] {
  font-size: 0.8rem !important;
}

.react-autosuggest__input {
  width: 150px;
}

#successfullyCreatedModal {
  font-size: 1.3rem;
  color: rgb(9, 151, 16);
  transition: all 0.2s linear;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: -1rem;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#modalForLoginError {
  font-size: 1.2rem;
  color: rgb(228, 15, 15);
  margin: 1.1rem 0;
  transition: all 0.2s linear;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#loggedInMsg {
  font-size: 1.3rem;
  color: rgb(9, 151, 16);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: -1.5rem;
}

#modalForSignupError {
  font-size: 1.3rem;
  color: rgb(228, 15, 15);
  transition: all 0.2s linear;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.react-datepicker__month {
  margin: 0 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.4rem !important;
  line-height: 1.4rem !important;
  text-align: center;
  margin: 0.1rem !important;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  color: #064a8a;
  font-size: 14px;
  /* z-index: 14 !important; */
  z-index: 1000 !important;
  /* top: 26px; */
}

.react-autosuggest__suggestion {
  cursor: pointer;
  width: 100%;
  list-style: none;
  padding: 0.3rem;
  background-color: #fff;
  /* z-index: 3 !important; */
}

.react-autosuggest__suggestions-list {
  width: 200px;
  /* width: 100%; */
  padding: 0;
  /* z-index: 5 !important; */
}

#addressAutocomplete .react-autosuggest__suggestions-list {
  width: 100%;
  padding: 0;
  /* z-index: 5 !important; */
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input:focus {
  z-index: -1 !important;
  box-shadow: 0 0 3px rgb(170, 168, 168);
}

#loadDatesWrapper .react-datepicker-wrapper {
  max-width: 110px;
  text-align: center;
}

#loadDatesWrapper .react-datepicker-wrapper,
.react-datepicker-wrapper input {
  max-width: 100px;
  text-align: center;
}

#loadDatesWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#loadDateContainer {
  display: flex;
  position: relative;
}

#loadDateContainer #timezone {
  position: absolute;
  left: 205px;
  top: 5px;
}

#loadDateContainer .react-datepicker-wrapper input {
  width: 240px;
  max-width: 240px;
  min-width: 210px;
  text-align: left;
}
