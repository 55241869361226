.DateRangePickerInput {
	display: flex;
	align-items: center;
	height: 40px;
}

.DateInput_input {
	text-align: center;
	padding: 0;
}
