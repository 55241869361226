.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.datepicker-error {
  border: 1px solid red !important;
}

