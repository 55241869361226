

.dropdown-item {
  color: #121212 !important; 
}

.autocomplete-container {
	position: relative;
	display: inline-block;
	width: 100%;
}

.autocomplete-container input {
	width: 100%;
	font-size: 14px; /* Decrease font size for selected cities in input */
	box-shadow: none; /* Remove shadow */

}

.autocomplete-container .dropdown-menu {
	position: absolute;
	z-index: 1;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;
	border: 0px solid #ccc;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	border: 1px solid #ddd; 
	border-top: none;
	border-radius: 0 0 8px 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
	background-color: #fafafa; 
	z-index: 1000;
	overflow: hidden; 
}


.autocomplete-container .dropdown-item:hover {
	background-color: #e6e6e6; 
}


ul.dropdown-menu {
  border: 0px solid #ccc;
}

.dropdown-menu.dark-mode {
	background-color: #1a1a1a; 
	color: white; /* Change to your preferred dark text color */
}


.dropdown-menu.light-mode {
	background-color: white; 
	color: #121212; 
}

.autocomplete-container .dropdown-item {
	/* color: #121212; */
	padding: 7px 15px; 
	font-size: 14px;
	/* background-color: white; */
	font-family: 'Arial', sans-serif;
	cursor: pointer; 
	transition: all 0.2s; 
}

/* For dark mode */
.dark-mode .dropdown-item {
	background-color: #1a1a1a !important; /* Dark background color for list item */
	color: white !important;/* Dark text color for list item */
}

/* For light mode */
.light-mode .dropdown-item {
	background-color: white !important; /* Light background color for list item */
	color: #121212 !important; /* Light text color for list item */
}


/* For dark mode highlighted item */
.dark-mode .dropdown-item.is-highlighted {
	background-color: #3a3a3a !important; /* Dark highlighted background color */
}

/* For light mode highlighted item */
.light-mode .dropdown-item.is-highlighted {
	background-color: #e6e6e6 !important; /* Light highlighted background color */
}